import React, { memo, useMemo, useState } from 'react';
import { Wrapper } from './ido.styled';
import Header from '@/components/header/header';
import Banner from '@/assets/home/banner_dark.png';
import Logo from '@/assets/home/Logo.png';
import Social1 from '@/assets/social/social1.png';
import Social2 from '@/assets/social/social2.png';
import Social3 from '@/assets/social/social3.png';
import Social4 from '@/assets/social/social4.png';
import Graphic1 from '@/assets/ido/graphic1.png';
import BNB from '@/assets/33439.png';
import USDT from '@/assets/home/usdt.png';
import Help from '@/assets/ido/help-circle.png';
import ProjectInterview from '@/assets/ido/prj.png';
import Backer from '@/assets/ido/backer.png';
import { BuySection } from './components/buy-section';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { LaunchPadRepository } from '@/repository/launchpad.repository';
import { lowerCacheOptions } from '@/constants/app.constant';
import { useCountDown } from '@/hooks/use-count-down';
import { useContractProviderContext } from '@/contexts/contract-provider-context';
import { ethers } from 'ethers';
import { useAddress } from '@thirdweb-dev/react';
import withConnect from '@/hoc/with-connect';
import ArrowDown from '@/assets/ido/arrow-right.png';
const social = [Social1, Social2, Social3, Social4];

const IDOPageComponent = ({ connectedAccount, allLaunchpad }: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const token = useSelector(selectToken);
  const address = useAddress();
  const { provider } = useContractProviderContext();

  const currentLaunchPad = useMemo(() => {
    return allLaunchpad?.[0];
  }, [allLaunchpad]);
  console.log('allLaunchpad', currentLaunchPad?.address);
  const { data: userAllocation } = useQuery({
    queryKey: ['retrieveUserAllocation', currentLaunchPad?.address, token],
    queryFn: () => LaunchPadRepository.getUserAllocation(currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address,
    refetchInterval: 50000
  });

  const { data: totalBought } = useQuery({
    queryKey: ['retrieveTotalBought', currentLaunchPad?.address, token],
    queryFn: () => LaunchPadRepository.getTotalBought(currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address,
    refetchInterval: 10000
  });
  console.log('totalBought', totalBought);
  const { data: userResult } = useQuery({
    queryKey: ['retrieveUserResult', currentLaunchPad?.address, token, connectedAccount, address],
    queryFn: () => LaunchPadRepository.getUserResult(connectedAccount || address || '', currentLaunchPad?.address),
    retry: false,
    enabled: !!currentLaunchPad?.address && !!address,
    refetchInterval: 50000
  });

  const { data: totalInvested } = useQuery({
    queryKey: ['retrieveTotalInvested', currentLaunchPad?.address, token],
    queryFn: () => LaunchPadRepository.getTotalInvested(provider, currentLaunchPad?.address),
    retry: false,
    enabled: !!provider?.network && !!currentLaunchPad?.address,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });

  console.log('totalInvested', totalInvested);

  const {
    timerDays: startDays,
    timerHours: startHours,
    timerMinutes: startMinutes,
    timerSeconds: startSeconds,
    countdownDone: isStart
  } = useCountDown((currentLaunchPad?.publicStartTime || 0) * 1000, '1');

  const {
    timerDays: endDays,
    timerHours: endHours,
    timerMinutes: endMinutes,
    timerSeconds: endSeconds,
    countdownDone: isEnd
  } = useCountDown((currentLaunchPad?.publicEndTime || 0) * 1000, '2');

  const buyProgress = useMemo(() => {
    const bought = Number(ethers.utils.formatEther(totalBought?.total_bought || '0'));
    const raise = Number(ethers.utils.formatEther(totalBought?.total_raise || '1'));
    const rate = (bought * 100) / raise;

    let rateShow = rate;
    let overate = 0;
    if (rate > 100) {
      rateShow = (100 * 100) / rate;
      overate = rate - 100;
    }
    return {
      bought,
      raise,
      rate,
      overate,
      rateShow
    };
  }, [totalBought]);

  console.log('rate', buyProgress);

  return (
    <Wrapper>
      <Header />
      <img src={Banner} className='absolute top-0 left-0 w-full z-[1]' alt='' />
      <div className='relative z-[2] flex flex-col items-center  mt-[152px] max-mobile:mt-[112px] w-full max-w-[1136px] px-4'>
        <div className='font-bold text-[64px] max-mobile-xl:text-[52px] max-mobile:text-[44px] max-mobile-sm:text-[40px]  w-full text-center'>
          HyperLaunch IDO
        </div>
        <div className='w-full items-center justify-center gap-4 flex mt-10'>
          <div
            onClick={() => {
              setActiveTab(0);
            }}
            className={`w-[272px] h-[64px] max-mobile:h-[48px] max-mobile:w-[164px] flex items-center justify-center gap-2 border-[1px] border-[#FFFFFF] rounded-2xl font-normal cursor-pointer hover:opacity-85 transition-all ${activeTab === 0 && '!bg-[#FFFFFF] !text-[#181E79] !font-bold '}`}
          >
            INVEST
          </div>
          <div
            onClick={() => {
              setActiveTab(1);
            }}
            className={`w-[272px] h-[64px] max-mobile:w-[164px] max-mobile:h-[48px] flex items-center justify-center gap-2 border-[1px] border-[#FFFFFF] rounded-2xl font-normal cursor-pointer  hover:opacity-85 transition-all ${activeTab === 1 && '!bg-[#FFFFFF] !text-[#181E79] !font-bold '}`}
          >
            INFORMATION
          </div>
        </div>
        {activeTab === 0 ? (
          <>
            <div className='mt-10 w-full bg-[#070A33] backdrop-blur-[200px] rounded-[15px] flex max-mobile:flex-col'>
              <div className='p-6 border-r-[1px] max-mobile:border-r-[0px] border-[#42467D] flex-[0.45] max-mobile:p-4'>
                <div className='flex w-full justify-between items-center'>
                  <div className='flex flex-col items-start gap-2'>
                    <div className='font-semibold text-sm'>HyperLaunch origin</div>
                    <div className='flex items-center gap-1'>
                      <img src={Logo} className='w-6' alt='' />
                      <div className='font-semibold text-[#948E9C] text-sm'>$HYPE</div>
                    </div>
                    <div className='flex items-center gap-3'>
                      {social.map((sc, key) => {
                        return (
                          <div className='w-6' key={key}>
                            <img src={sc} alt='' />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <img src={Graphic1} className='w-[30%] max-w-[120px]' alt='' />
                </div>
                <div className='grid grid-cols-4 gap-3 mt-9 w-full max-mobile:grid-cols-2 '>
                  <div className='flex flex-col gap-1'>
                    <div className='text-xs text-[#948E9C]'>IDO price</div>
                    <div className='flex items-center gap-1'>
                      <img src={USDT} className='w-4' alt='' />
                      <div className='font-semibold text-sm'>0.01 USDT</div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-xs text-[#948E9C]'>Total raise</div>
                    <div className='flex items-center gap-1'>
                      <div className='font-semibold text-sm'>$150,000</div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-xs text-[#948E9C]'>Token network</div>
                    <div className='flex items-center gap-1'>
                      <img src={BNB} className='w-4' alt='' />
                      <div className='font-semibold text-sm'>LUMIA</div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <div className='text-xs text-[#948E9C]'>IDO network</div>
                    <div className='flex items-center gap-1'>
                      <img src={BNB} className='w-4' alt='' />
                      <div className='font-semibold text-sm'>LUMIA</div>
                    </div>
                  </div>
                </div>
                <div className='mt-6 w-full   bg-[#FFFFFF1A] rounded-2xl p-4 flex flex-col items-start gap-2'>
                  <div className='font-bold text-[20px]'>Vesting</div>
                  <div className='font-normal text-sm text-[#948E9C]'>
                    20% realease at TGE - 1 months cliff - 4 months linear vesting
                  </div>
                </div>
              </div>

              <div className='w-full flex items-center flex-col flex-[0.55]'>
                <div className='w-full flex justify-between p-6 !pl-[31px] flex-[0.95]  max-mobile:!p-4 max-mobile:min-h-[200px] max-mobile-sm:min-h-[280px]'>
                  <div className='flex-[0.45] flex-col flex'>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-bold  max-mobile:text-xs'>Pre-order</div>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Oct 24, 2024 17:00 (GMT+7)
                      </div>
                    </div>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-bold max-mobile:text-xs'>Buying phase - guaranteed</div>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Oct 24, 2024 17:00 (GMT+7)
                      </div>
                    </div>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-bold max-mobile:text-xs'>Buying phase - FCS</div>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Oct 24, 2024 17:00 (GMT+7)
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col flex-[0.05] mt-[8px]'>
                    <div className='flex flex-col flex-[0.667] relative z-[1]'>
                      <div className='flex-1  relative z-[2]'>
                        <div className='w-2 h-2 rounded-full bg-[#D9D9D9] '></div>
                      </div>
                      <div className='flex-1 relative z-[2]'>
                        <div className='w-2 h-2 rounded-full bg-[#D9D9D9] '></div>
                      </div>
                      <div className='absolute w-[2px] h-full bg-[#42467D] z-[1] left-[2.5px] top-[1px]'></div>
                    </div>
                    <div className='flex-[0.333] relative z-[2]'>
                      <div className='w-2 h-2 rounded-full bg-[#D9D9D9] '></div>
                    </div>
                  </div>
                  <div className='flex-[0.5] flex-col flex'>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Whitelist winner requireed. Guaranteed basis.
                      </div>
                    </div>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Whitelist winner requireed. Guaranteed basis.
                      </div>
                    </div>
                    <div className='flex-1 flex flex-col items-start gap-1'>
                      <div className='text-sm font-medium text-[#948E9C] max-mobile:text-xs'>
                        Extra allocation. Whitelist required.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='flex items-center gap-2 mt-2 cursor-pointer'
                  onClick={() => {
                    const element = document.getElementById('homepage-nft-detail');
                    if (!element) {
                      return;
                    }
                    element.scrollIntoView({
                      behavior: 'smooth'
                    });
                  }}
                >
                  <span className='font-bold text-[24px] text-[#1580FF] '>Invest now</span>
                  <img src={ArrowDown} className='w-6' alt='' />
                </div>
              </div>
            </div>
            <div className='font-bold text-[64px] max-mobile-xl:text-[52px] max-mobile:text-[44px] max-mobile-sm:text-[40px]  w-full text-center mt-20'>
              INVEST
            </div>
            <div className='w-full flex items-center justify-between mt-4'>
              <div className='flex items-center gap-4'>
                <div className='w-2 h-8 bar rounded'></div>
                <div className='font-medium text-[32px] max-mobile:text-[20px] max-mobile-sm:text-[16px]'>
                  $
                  {buyProgress?.bought?.toLocaleString('en', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0
                  })}
                  {` `}/ $
                  {buyProgress?.raise?.toLocaleString('en', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0
                  })}
                </div>
              </div>
              <div className='font-medium text-[32px] max-mobile:text-[20px] max-mobile-sm:text-[16px]'>
                {buyProgress?.rate?.toLocaleString('en', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0
                })}
                %
              </div>
            </div>
            <div className='w-full mt-6 bg-[#293657] rounded h-10 max-mobile:h-6 max-mobile-sm:h-4 relative'>
              <div
                className='h-full relative bg-[#FFFFFF] rounded-s z-[2]'
                style={{
                  width: `${Math.min(buyProgress?.rateShow, 100)}%`
                }}
              >
                <div className='absolute h-[64px] max-mobile:h-12 max-mobile-sm:h-8 w-[2px] bg-[#FFFFFF] progress-bar top-1/2 -translate-y-1/2 right-0'></div>
              </div>
              <div
                className='h-full absolute right-0 bg-[#0FCE78] rounded-s top-0 z-[1]'
                style={{
                  width: `${Math.min(buyProgress?.overate, 100)}%`
                }}
              ></div>
            </div>
            {buyProgress?.overate > 0 && (
              <div className='bg-[#062919] rounded-lg py-2 px-4 font-semibold text-[#0FCE78] text-[16px] self-end mt-[20px] max-mobile:mt-[12px] max-mobile:text-[14px] max-mobile:py-1 max-mobile:px-2'>
                {buyProgress?.overate}% overfunded
              </div>
            )}
            <div id='homepage-nft-detail'></div>
            {!isEnd ? (
              <div className='mt-10 flex flex-col items-center w-full'>
                <div className='font-normal text-[24px]'>
                  {!isStart ? 'Sale starts in:' : !isEnd ? 'Sale ends in:' : 'Sale ended'}
                </div>
                <div className='relative'>
                  <div className={`relative ${isStart && 'opacity-0 invisible'}`}>
                    <div className='mt-4 flex gap-2 justify-center w-full items-center'>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {startDays}d
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>

                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {startHours}h
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {startMinutes}m
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {startSeconds}s
                      </div>
                    </div>
                  </div>
                  <div
                    className={`absolute top-0 left-0 ${!isStart || isEnd ? 'opacity-0 invisible' : 'opacity-100 '}`}
                  >
                    <div className='mt-4 flex gap-2 justify-center w-full items-center'>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {endDays}d
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>

                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {endHours}h
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {endMinutes}m
                      </div>
                      <div className='font-semibold text-[24px] flex items-center justify-center text-center'>:</div>
                      <div className='bg-[#141960] flex items-center justify-center text-center  font-semibold text-[20px] w-[92px] h-[92px] rounded-2xl'>
                        {endSeconds}s
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-12 flex items-center gap-1'>
                  <span className=' font-semibold text-[24px]'>You are</span>
                  <span
                    className={` font-semibold text-[24px] ${userAllocation ? 'text-[#0FCE78]' : 'text-[#1580FF]'}`}
                  >
                    {userAllocation ? 'Priority' : 'Public'} user
                  </span>
                  <span className=' font-semibold text-[24px]'>.</span>
                  <img src={Help} className='w-6' alt='' />
                </div>
                <div className='w-full mt-10 rounded-2xl bg-[#070A33] p-6 flex items-start gap-6 max-[800px]:flex-col-reverse max-mobile:p-4'>
                  <div className='flex flex-[0.6] flex-col gap-6 max-[800px]:w-full '>
                    <div className='flex items-center gap-4 max-[800px]:hidden'>
                      <div className={`w-2 h-8 rounded ${userAllocation ? 'bg-[#0FCE78]' : 'bg-[#1580FF]'} `}></div>
                      <div className='font-bold text-[24px]'>{userAllocation ? 'Priority' : 'Public'} funding</div>
                    </div>
                    <div className='w-full h-[1px] bg-[#42467D]'></div>
                    <div className='grid grid-cols-2 gap-4  max-mobile:grid-cols-1'>
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>Token name</div>
                        <div className='flex items-center gap-1'>
                          <img src={Logo} className='w-4' alt='' />
                          <div className='font-semibold text-sm'>HYPER LAUNCH (HYPE)</div>
                        </div>
                      </div>
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>Funding Type</div>
                        <div className='flex items-center gap-1'>
                          <div className='font-semibold text-sm'>{userAllocation ? 'Priority' : 'Public'} Funding</div>
                        </div>
                      </div>
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>Price</div>
                        <div className='flex items-center gap-1'>
                          <img src={USDT} className='w-4' alt='' />
                          <div className='font-semibold text-sm'>{currentLaunchPad?.allocationPrice} USDT</div>
                        </div>
                      </div>
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>Min invest amount</div>
                        <div className='flex items-center gap-1'>
                          <div className='font-semibold text-sm'>
                            $
                            {Number(ethers.utils.formatEther(currentLaunchPad?.minInvestment || 0))?.toLocaleString(
                              'en',
                              {
                                maximumFractionDigits: 5,
                                minimumFractionDigits: 0
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>Max allocation per round</div>
                        <div className='flex items-center gap-1'>
                          <div className='font-semibold text-sm'>
                            {!!userAllocation && currentLaunchPad?.maxAmountPerAcc !== '0'
                              ? Number(
                                  ethers.utils.formatEther(currentLaunchPad?.maxAmountPerAcc || 0)
                                )?.toLocaleString('en', {
                                  maximumFractionDigits: 5,
                                  minimumFractionDigits: 0
                                })
                              : 'No max cap'}{' '}
                          </div>
                        </div>
                      </div>{' '}
                      <div className='flex flex-col items-start gap-1'>
                        <div className='font-medium text-sm text-[#948E9C]'>
                          {!isStart ? 'Sale starts in:' : !isEnd ? 'Sale ends in:' : 'Sale ended'}
                        </div>
                        <div className='flex items-center gap-1 relative'>
                          <div className={`font-semibold text-sm relative ${isStart && 'opacity-0 invisible'}`}>
                            {startDays}d:{startHours}h:{startMinutes}m:{startSeconds}s
                          </div>
                          <div
                            className={`font-semibold text-sm absolute top-0 left-0 ${!isStart || isEnd ? 'opacity-0 invisible' : 'opacity-100 '}`}
                          >
                            {endDays}d:{endHours}h:{endMinutes}m:{endSeconds}s
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isStart ? (
                    <>
                      <BuySection
                        currentLaunchPad={currentLaunchPad}
                        isStart={isStart}
                        isEnd={isEnd}
                        privateAllocation={userAllocation}
                      />
                      <div className='hidden items-center gap-4 max-[800px]:flex'>
                        <div className={`w-2 h-8 rounded ${userAllocation ? 'bg-[#0FCE78]' : 'bg-[#1580FF]'} `}></div>
                        <div className='font-bold text-[24px]'>{userAllocation ? 'Priority' : 'Public'} funding</div>
                      </div>
                    </>
                  ) : (
                    <div className='flex flex-[0.4] flex-col items-center self-stretch bg-[#141960] rounded-2xl p-6 gap-6 justify-center text-center font-bold text-[24px] text-[#948E9C]'>
                      Sale starts soon!
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className='text-[#ED373A] bg-[#141960] px-10 py-4 rounded-2xl font-semibold text-[24px] mt-7'>
                Sale ended
              </div>
            )}

            {/* <div className='font-bold text-[40px] text-center max-mobile:text-[32px]'>Claim your token</div> */}

            {/* {!userResult?.public_bought_valid && !userResult?.private_bought_valid && (
              <div className='w-full mt-10 max-mobile:mt-3 rounded-2xl bg-[#070A33] p-6 flex items-start flex-col gap-6 '>
                <div className='flex items-center gap-4'>
                  <div className='w-2 h-8 rounded bg-[#1580FF]'></div>
                  <div className='font-bold text-[24px]'>{!userAllocation ? 'Public' : 'Priority'} funding</div>
                </div>
                <div className='w-full h-[1px] bg-[#42467D] max-[800px]:hidden'></div>
                <div className='w-full flex items-center gap-10 max-[800px]:flex-col-reverse'>
                  <div className='flex-[0.65] flex-col gap-6 flex max-[800px]:w-full'>
                    <div className='w-full flex items-center justify-between'>
                      <div className='font-normal text-sm text-[#948E9C]'>Total fund you submited</div>
                      <div className='flex items-center gap-2'>
                        <div className='font-semibold text-sm'>... USDT</div>
                        <img src={USDT} className='w-4' alt='' />
                      </div>
                    </div>
                    <div className='w-full hidden h-[1px] bg-[#42467D] max-[800px]:block'></div>
                    {!userAllocation && (
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Actual fund project receive</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>... USDT</div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                    )}
                    <div className='w-full flex items-center justify-between'>
                      <div className='font-normal text-sm text-[#948E9C]'>Token amount your receive</div>
                      <div className='flex items-center gap-2'>
                        <div className='font-semibold text-sm'>... HYPE</div>
                        <img src={Logo} className='w-4' alt='' />
                      </div>
                    </div>
                    {!userAllocation && (
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Your refund</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>... USDT</div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                    )}
                  </div>
                  <ClaimSection currentLaunchPad={currentLaunchPad} type='pending' userResult={userResult} />
                </div>
              </div>
            )} */}
            {/* {!!userResult?.public_bought_valid &&
              Number(ethers.utils.formatEther(userResult?.public_bought_valid)) !== 0 && (
                <div className='w-full mt-10 max-mobile:mt-3 rounded-2xl bg-[#070A33] p-6 flex items-start flex-col gap-6 '>
                  <div className='flex items-center gap-4'>
                    <div className='w-2 h-8 rounded bg-[#1580FF]'></div>
                    <div className='font-bold text-[24px]'>Public funding</div>
                  </div>
                  <div className='w-full h-[1px] bg-[#42467D] max-[800px]:hidden'></div>
                  <div className='w-full flex items-center gap-10 max-[800px]:flex-col-reverse'>
                    <div className='flex-[0.65] flex-col gap-6 flex max-[800px]:w-full'>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Total fund you submited</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(ethers.utils.formatEther(userResult?.public_bought_valid || '0'))?.toLocaleString(
                              'en',
                              {
                                maximumFractionDigits: 5,
                                minimumFractionDigits: 0
                              }
                            )}{' '}
                            USDT
                          </div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                      <div className='w-full hidden h-[1px] bg-[#42467D] max-[800px]:block'></div>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Actual fund project receive</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(
                              ethers.utils.formatEther(userResult?.public_bought_allocation || '0')
                            )?.toLocaleString('en', {
                              maximumFractionDigits: 5,
                              minimumFractionDigits: 0
                            })}{' '}
                            USDT
                          </div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Token amount your receive</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(
                              ethers.utils.formatEther(userResult?.public_token_allocation || '0')
                            )?.toLocaleString('en', {
                              maximumFractionDigits: 5,
                              minimumFractionDigits: 0
                            })}{' '}
                            HYPE
                          </div>
                          <img src={Logo} className='w-4' alt='' />
                        </div>
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Your refund</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(ethers.utils.formatEther(userResult?.public_bought_refund || '0'))?.toLocaleString(
                              'en',
                              {
                                maximumFractionDigits: 5,
                                minimumFractionDigits: 0
                              }
                            )}{' '}
                            USDT
                          </div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                    </div>
                    <ClaimSection currentLaunchPad={currentLaunchPad} type='public' userResult={userResult} />
                  </div>
                </div>
              )} */}

            {/* {!!userResult?.private_bought_valid &&
              Number(ethers.utils.formatEther(userResult?.private_bought_valid)) !== 0 && (
                <div className='w-full mt-2 rounded-2xl bg-[#070A33] p-6 flex items-start flex-col gap-6 '>
                  <div className='flex items-center gap-4'>
                    <div className='w-2 h-8 rounded bg-[#0FCE78]'></div>
                    <div className='font-bold text-[24px]'>Priority funding</div>
                  </div>
                  <div className='w-full h-[1px] bg-[#42467D] max-[800px]:hidden'></div>
                  <div className='w-full flex items-start gap-10  max-[800px]:flex-col-reverse'>
                    <div className='flex-[0.65] flex-col gap-6 flex max-[800px]:w-full'>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Total fund in priority pool</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(
                              ethers.utils.formatEther(userResult?.private_bought_allocation || '0')
                            )?.toLocaleString('en', {
                              maximumFractionDigits: 5,
                              minimumFractionDigits: 0
                            })}{' '}
                            USDT
                          </div>
                          <img src={USDT} className='w-4' alt='' />
                        </div>
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <div className='font-normal text-sm text-[#948E9C]'>Total $HYPE you receive</div>
                        <div className='flex items-center gap-2'>
                          <div className='font-semibold text-sm'>
                            {Number(
                              ethers.utils.formatEther(userResult?.private_token_allocation || '0')
                            )?.toLocaleString('en', {
                              maximumFractionDigits: 5,
                              minimumFractionDigits: 0
                            })}{' '}
                            HYPE
                          </div>
                          <img src={Logo} className='w-4' alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='w-full hidden h-[1px] bg-[#42467D] max-[800px]:block'></div>
                    <ClaimSection currentLaunchPad={currentLaunchPad} type='private' userResult={userResult} />
                  </div>
                </div>
              )} */}
          </>
        ) : (
          <>
            <div className='w-full mt-20  rounded-2xl bg-[#070A33] backdrop-blur-[200px]'>
              <div className='w-full flex items-center justify-between p-10'>
                <div className='font-medium text-[20px]'>Research</div>
                <div className='flex items-center gap-4 '>
                  {social.map((sc, key) => {
                    return (
                      <div className='' key={key}>
                        <img src={sc} className='w-6' alt='' />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='w-full h-[1px] bg-[#42467D]'></div>
              <div className='w-full p-10 flex items-start gap-2 justify-between'>
                <div className='flex-[0.25] flex flex-col gap-6 max-mobile-xl:hidden'>
                  <div
                    className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}
                    onClick={() => {
                      document.querySelector('#info-1')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    }}
                  >
                    Landscape
                  </div>
                  <div
                    className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}
                    onClick={() => {
                      document.querySelector('#info-2')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    }}
                  >
                    Project Overview
                  </div>
                  <div
                    className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}
                    onClick={() => {
                      document.querySelector('#info-3')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    }}
                  >
                    Backers
                  </div>
                  <div
                    className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}
                    onClick={() => {
                      document.querySelector('#info-4')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    }}
                  >
                    Target Market
                  </div>
                  <div
                    className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}
                    onClick={() => {
                      document.querySelector('#info-5')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    }}
                  >
                    Product Solutions
                  </div>
                  <div className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}>
                    Technology Uses
                  </div>
                  <div className={`font-normal text-[20px] text-[#948E9C] ${true && '!font-medium !text-[#FFFFFF]'}`}>
                    Token Economy
                  </div>
                </div>

                <div className='flex-[0.7] flex flex-col gap-10 max-mobile-xl:flex-1'>
                  <div className='flex flex-col gap-4 w-full'>
                    <div className='font-medium text-[20px]' id='info-1'>
                      Landscape
                    </div>
                    <div className='font-normal text-base text-[#948E9C]'>
                      Social media platforms like Twitter and Facebook along with others have been censoring content
                      that might be too political, too controversial, or simply too different from the opinions of big
                      tech overlords. While even content around crypto has been falling victim to censorship it is
                      possible you might not be feeling the effect yet. What we’re seeing with censorship is just the
                      beginning, and is alarming for the future of free speech on the internet.
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 w-full' id='info-2'>
                    <div className='font-medium text-[20px]'>Project overview</div>
                    <img src={ProjectInterview} className='w-full' alt='' />
                    <div className='font-normal text-base text-[#948E9C]'>
                      Social media platforms like Twitter and Facebook along with others have been censoring content
                      that might be too political, too controversial, or simply too different from the opinions of big
                      tech overlords. While even content around crypto has been falling victim to censorship it is
                      possible you might not be feeling the effect yet. What we’re seeing with censorship is just the
                      beginning, and is alarming for the future of free speech on the internet.
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 w-full' id='info-3'>
                    <div className='font-medium text-[20px]'>Backers</div>
                    <div className='font-normal text-base text-[#948E9C]'>
                      Solcial has raised a total of $2.9 million and received support from some of the top names in the
                      blockchain space.
                    </div>
                    <img src={Backer} className='w-full' alt='' />
                  </div>
                  <div className='flex flex-col gap-4 w-full' id='info-4'>
                    <div className='font-medium text-[20px]'>Target Market</div>
                    <div className='font-normal text-base text-[#948E9C]'>
                      Solcial is building to redesign the conventional social media landscape. Social main target
                      audience is crypto natives and people familiar with blockchains, wallets, trading, NFTs, etc.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};
export const IDOPage = memo(withConnect(IDOPageComponent));
